import { ref } from 'vue'
import { defineStore } from 'pinia'

import { useToastStore, useSettingsStore } from './'
import { useBundleProductsStore } from './shopify'
import { useChargeStore } from '@/stores/recharge'
import { createRecharge } from '@/services/recharge'

import { initGTM } from '@/services/gtm-logger'

export const useAppStore = defineStore('app', () => {
  const settingsStore = useSettingsStore()
  const bundleProducts = useBundleProductsStore()
  const chargeStore = useChargeStore()

  // expose alert & handleError as a convenience
  const { alert, handleError } = useToastStore()

  // is app in browser or installed?
  const standalone = import.meta.browser && matchMedia('(display-mode: standalone)').matches

  const showWelcomeScreen = ref(isNativeApp)
  const navVisible = ref(false)
  const navProfileVisible = ref(false)
  const blocking = ref<boolean>(false) // top level blocking loader
  const blockingLabel = ref<string>()

  const paymentMethodIssue = computed(() => chargeStore.hasInvalidCharge)

  const init = async () => {
    createRecharge()
    await settingsStore.getSettings()
    await bundleProducts.load()
    // TODO: add error state if bundle products don't load
    initGTM()
  }

  const setBlocking = (value: boolean | string) => {
    if (typeof value === 'string') {
      blockingLabel.value = value
      value = true
    } else if (value) {
      blockingLabel.value = undefined
    }
    blocking.value = value
  }

  const modal = useDynamicComponent()
  const sheet = useDynamicComponent()

  const reset = () => {
    navVisible.value = navProfileVisible.value = blocking.value = false
    blockingLabel.value = undefined
  }

  return {
    standalone,
    showWelcomeScreen,
    navVisible,
    navProfileVisible,
    blocking,
    blockingLabel,
    paymentMethodIssue,
    init,
    modal,
    sheet,
    alert,
    handleError,
    setBlocking,
    reset
  }
}, {
  persist: {
    paths: [ 'showWelcomeScreen' ],
    storage: persistedState.localStorage
  }
})
