export default defineNuxtPlugin(async () => {
  const app = useAppStore()
  const toast = useToastStore()
  await app.init()
  return {
    provide: {
      app,
      toast
    }
  }
})
