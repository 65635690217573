export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=5.0"},{"charset":"utf-8"},{"class":"junip-store-key","name":"junip-store-key","data-store-key":"STAXp55iFd5dznSGJJX8NUbB","data-onsite-enabled":true,"data-review-count-enabled":true,"data-star-color":"#FDB600","content":"junip-store-key"}],"link":[],"style":[],"script":[{"src":"https://rapid-cdn.yottaa.com/rapid/lib/xoP209Ex003ukg.js"},"(function(h,o,t,j,a,r){\n          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n          h._hjSettings={hjid:3664620,hjsv:6};\n          a=o.getElementsByTagName('head')[0];\n          r=o.createElement('script');r.async=1;\n          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n          a.appendChild(r);\n        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"],"noscript":[],"htmlAttrs":{"lang":"en-US"},"title":"Customer Portal","titleTemplate":"%s - Oats Overnight","charset":"utf-8","viewport":"width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=5.0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000