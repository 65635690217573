import { defineStore } from 'pinia'
import { differenceInHours, parseISO } from 'date-fns'

import { resetPinia } from '@/services/pinia'

import type { Session as RechargeSession } from '@rechargeapps/storefront-client'
import type { Credentials } from '@/types'
import type { CustomerAccessToken, CustomerActivation, CustomerSignup } from '@/types/shopify'

const log = logger('auth')

export const useAuthStore = defineStore('auth', () => {

  const { $sentry } = useNuxtApp()
  const gtm = useGtm()
  const admin = useAdminStore()
  const shopify = shopifyStores.useAuthStore()
  const recharge = rechargeStores.useAuthStore()
  const { useCustomerAuthHeaders } = shopifyStores.useCustomerStore()

  const loading = useState<boolean>('loading', () => false)
  const lastLoginAt = useState<string>('lastLoginAt')
  const authenticated = useState<boolean>('authenticated', () => false)
  const sessionsPresent = computed(() => !!shopify.accessToken && !!recharge.session)
  const adminAuthenticated = computed(() => !!admin.jwt)

  const checkout_url = useState('checkout_url')

  const handleAuthenticated = async () => {
    try {
      await Promise.all([
        fetchWithRetry(() => shopify.handleAuthenticated()),
        fetchWithRetry(() => recharge.handleAuthenticated())
      ])
      updateLastLoginDate()

      if (shopify.customer) {
        const { id, email } = shopify.customer
        $sentry.setUser({ id, email })
        if (window.dataLayer) {
          window.dataLayer.push({ shopifyCustomerId: id })
        }
      }
      return authenticated.value = !!shopify.customer
    } catch (e) {
      log('handleAuthenticated: error', e)
      sendToSentry(e)
    }
  }

  const createRechargeSession = async (token: CustomerAccessToken) => {
    const session = token?.accessToken && await fetchWithRetry(() => recharge.authenticateAccessToken(token.accessToken))
    if (session) {
      return await handleAuthenticated()
    } else {
      gtm?.trackEvent({
        event: 'issue',
        category: 'login',
        action: 'createRechargeSession'
      })
    }

    throw new AuthError()
  }

  const authenticate = async ({ email, password }: Credentials) => {
    const result = await shopify.authenticate(email, password)
    const rechargeResult = await createRechargeSession(result)
    if (checkout_url.value && recharge.session) {
      await authenticateMultipass(recharge.session)
    }
    return rechargeResult
  }

  const signup = async (account: CustomerSignup) => {
    const { firstName, lastName, email } = account
    const { customer, shopifySession } = await shopify.signup(account)
    await recharge.signup({ shopifyId: customer.id, firstName, lastName, email })
    return createRechargeSession(shopifySession)
  }

  const activate = async (input: CustomerActivation) => {
    const result = await shopify.activate(input)
    return createRechargeSession(result)
  }

  const authenticateMultipass = async (pendingSession: RechargeSession) => {
    const token = await recharge.authenticateMultipass(pendingSession)
    await shopify.authenticateMultipass(token)
    return await handleAuthenticated()
  }

  const impersonateCustomer = async (email: string) => {
    try {
      const { token, session } = await adminFetch<{ token: string, session: RechargeSession }>('/api/auth/admin/impersonate', {
        method: 'POST',
        body: { email }
      })
      if (token && session) {
        recharge.session = session
        await shopify.authenticateMultipass(token)
        return await handleAuthenticated()
      }
    } catch (e) {
      sendToSentry(e)
    }

    throw new AuthError()
  }
  const updateLastLoginDate = () => {
    try {
      if (lastLoginAt.value && differenceInHours(parseISO(lastLoginAt.value), new Date()) < 1) {
        return
      }
      lastLoginAt.value = new Date().toISOString()
      $fetch('/api/auth/login-date', {
        method: 'PUT',
        headers: useCustomerAuthHeaders(),
        body: { customer_gid: shopify.customer?.id }
      })
    } catch (ex) {
      sendToSentry(ex)
    }
  }

  const refreshSession = async () => {
    if (shopify.accessToken) {
      return await recharge.authenticateAccessToken(shopify.accessToken)
    } else {
      $sentry.captureMessage('[auth] refresh session failed')
      return logout()
    }
  }

  const init = async () => {
    loading.value = true
    try {
      if (shopify.tokenExpiring()) {
        await shopify.renewAccessToken()
        await refreshSession()
      }
      if (sessionsPresent.value) {
        await handleAuthenticated()
      }
    } catch (e) {
      // $sentry.captureException(e)
      logout()
    } finally {
      loading.value = false
    }
  }

  const logout = () => {
    reset()
    resetPinia()
  }

  const reset = () => {
    $sentry.setUser(null)
    loading.value = authenticated.value = false
  }

  return {
    shopify: storeToRefs(shopify),
    recharge: storeToRefs(recharge),
    adminAuthenticated,
    loading,
    lastLoginAt,
    authenticated,
    authenticate,
    authenticateMultipass,
    signup,
    activate,
    impersonateCustomer,
    refreshSession,
    init,
    logout
  }
}, {
  persist: [
    {
      paths: [ 'lastLoginAt' ],
      storage: persistedState.localStorage
    }
  ]
})
