import type { CustomerAuthenticationResult, FieldError } from '@/types/shopify'

export interface CustomerActivateQuery {
  customerActivateByUrl: CustomerAuthenticationResult
}

export const CustomerActivate = `mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
  customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      field
      message
    }
  }
}`
