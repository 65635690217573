<template>
  <LoaderButton
    class="button"
    :loading="loading"
    @click="resetError"
  >
    <slot>
      Reload Page
    </slot>
  </LoaderButton>
</template>

<script setup lang="ts">
const { $pwa: { updateServiceWorker } } = useNuxtApp()
const emit = defineEmits([ 'reset' ])
const [ loading, resetError ] = useLoading(async () => {
  return new Promise((resolve) => {
    updateServiceWorker(true)
    setTimeout(() => {
      emit('reset')
      window.location.reload()
      resolve()
    }, 3000)
  })
})
</script>
